import { ReactNode, useState } from "react";
import Button from "./Button";
import CompanyAssociatedAliasesModal from "./CompanyAssociatedAliasesModal";
import { ICompanyDetail, IEquity } from "features/entity/entity.interfaces";
import useClock from "utils/hooks/useClock";
import CompanyAssociatedEquitiesModal from "./CompanyAssociatedEquitiesModal";
import { sortBy } from "lodash";

type CompanySection = {
  label: string;
  value: ReactNode;
  actions?: ReactNode[];
  fullWidth?: boolean;
};

const CompanyDetails = ({
  contacts = 1,
  company,
  isLoading,
}: {
  contacts: any;
  company?: ICompanyDetail;
  isLoading: boolean;
}) => {
  const [showAssociatedAliases, setShowAssociatedAliases] = useState(false);
  const [showAssociatedEquities, setShowAssociatedEquities] = useState(false);
  const {
    associatedAliases,
    sector,
    industry,
    marketCap,
    location,
    companyType,
    companySize,
    slogan,
    linkedinUrl,
    latitude,
    longitude,
  } = company || {};
  const equities = sortBy(
    company?.equities || [],
    ({ symbol }) => symbol,
  ) as IEquity[];
  const domain = associatedAliases?.find((alias) => alias.isPrimaryAlias)?.name;

  const knownContacts = contacts;

  const currentDate = useClock({
    pattern: "HH:mm",
    latitude,
    longitude,
  }) as string;

  const companySections: CompanySection[][] = [
    // section 1:
    [
      {
        label: "Website",
        value: domain,
        actions: [
          domain ? (
            <div className="pr-2">
              <Button
                icon="icon-popup"
                id="open-company-website"
                tooltip="Open website in new tab"
                color="transparent"
                disabled={isLoading}
                onClick={() => window.open(`https://${domain}`, "_blank")}
              />
            </div>
          ) : null,
          linkedinUrl ? (
            <div className="pl-2">
              <Button
                icon="icon-linkedin"
                id="open-company-linkedin"
                tooltip="Open LinkedIn in new tab"
                color="transparent"
                onClick={() => window.open(`https://${linkedinUrl}`, "_blank")}
              />
            </div>
          ) : null,
        ].filter(Boolean),
        fullWidth: true,
      },
      {
        label: "Associated domains",
        value:
          (associatedAliases?.length || 0) > 1
            ? associatedAliases!.length
            : null,
        fullWidth: true,
        actions: [
          associatedAliases?.length ? (
            <Button
              icon="icon-info-circle"
              id="open-associated-domains"
              tooltip="View associated domains"
              color="transparent"
              onClick={() => setShowAssociatedAliases(true)}
            />
          ) : null,
        ].filter(Boolean),
      },
    ].filter((item) => item.value) as CompanySection[],
    // section 2:
    [
      {
        label: "About",
        value: slogan,
        fullWidth: true,
      } as CompanySection,
    ].filter((item) => item.value) as CompanySection[],
    // section 3:
    [
      {
        label: "Employees",
        value: companySize?.replace("employees", ""),
      },
      {
        label: "Known contacts",
        value: knownContacts,
      },
      {
        label: "Sector",
        value: sector,
        fullWidth: true,
      },
      {
        label: "Industry",
        value: industry,
        fullWidth: true,
      },
      {
        label: "Company type",
        value: companyType,
      },
      {
        label: "Market cap",
        value: marketCap,
      },
      {
        label: "Headquarters",
        value: location,
        actions: [<div className="content-end text-metal">{currentDate}</div>],
        fullWidth: true,
      },
    ]?.filter((item) => item.value) as CompanySection[],
    // section 4:
    [
      {
        label: "Symbol",
        value:
          (equities?.length || 0) < 6 && (equities?.length || 0) > 0 ? (
            <div>{equities?.map(({ symbol }) => <div>{symbol}</div>)}</div>
          ) : null,
      },
      {
        label: "Exchange",
        value:
          (equities?.length || 0) < 6 && (equities?.length || 0) > 0 ? (
            <div>{equities?.map(({ exchange }) => <div>{exchange}</div>)}</div>
          ) : null,
      },
      {
        label: "Associated equities",
        value: (equities?.length || 0) > 6 ? equities!.length : null,
        fullWidth: true,
        actions: (
          <Button
            icon="icon-info-circle"
            id="open-associated-equities"
            tooltip="View associated equities"
            color="transparent"
            onClick={() => setShowAssociatedEquities(true)}
          />
        ),
      },
    ]?.filter((item) => item.value) as CompanySection[],
  ].filter((section) => section.length);

  return (
    <div className="flex flex-col gap-4">
      <CompanyAssociatedAliasesModal
        visible={showAssociatedAliases}
        domains={associatedAliases?.map(({ name }) => name)}
        companyName={company?.displayName}
        onClose={() => setShowAssociatedAliases(false)}
      />
      <CompanyAssociatedEquitiesModal
        visible={showAssociatedEquities}
        equities={equities}
        companyName={company?.displayName}
        onClose={() => setShowAssociatedEquities(false)}
      />
      {companySections.map((section, index) => (
        <div
          data-testid={`company-details-section-${index}`}
          key={index}
          className="grid rounded-lg p-4 border-dust-dark bg-white shadow grid-cols-2 gap-4 border"
        >
          {section.map((item, index) => (
            <div
              key={index}
              className={`${
                item.fullWidth ? "col-span-2 flex justify-between" : ""
              }`}
            >
              <div className="flex flex-col">
                <div className="text-metal">{item.label}</div>
                {isLoading ? (
                  <div className="h-4 w-32 animate-pulse rounded bg-dust"></div>
                ) : (
                  <div className="font-bold text-navy">{item.value}</div>
                )}
              </div>
              <div className="flex flex-row divide-x divide-dust">
                {item.actions}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CompanyDetails;
