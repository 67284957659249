import { pathOr, propOr } from "ramda";
import { safeJsonParse } from "utils/string";
import trend from "utils/trend";

const viewerMetric = (metric: string, row: any, defaultValue: any = 0) =>
  pathOr(defaultValue, ["viewer", metric], row);

export function extractSharedMetrics(
  row: any,
  personal: boolean,
): {
  rank: string;
  status: string;
  segment: string;
  relationshipScore: number;
  relationshipScoreTrend: string;
  colleaguesInteracting: number;
  interest: number;
  interestTrend: "increase" | "decrease" | "maintain";
  effort: number;
  effortTrend: "increase" | "decrease" | "maintain";
  lastInteraction: { date: string; contact: string; colleague: string };
  lastMeeting: { date: string; contact: string; colleague: string };
  viewerStatus: string;
  viewerInterest: number;
  viewerEffort: number;
  viewerRelationshipScore: number;
  viewerRelationshipScoreTrend: string;
  viewerLastInteraction: { date: string; contact: string; colleague: string };
  viewerLastMeeting: { date: string; contact: string; colleague: string };
  viewerEmailInbound: number;
  viewerEmailOutbound: number;
  viewerReplyResponseTimeFromThem: number;
  viewerReplyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  replyResponseTimeFromThem: number;
  replyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  firstReplyResponseTimeFromThem: number;
  firstReplyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  viewerFirstReplyResponseTimeFromThem: number;
  viewerFirstReplyResponseTimeFromThemTrend:
    | "increase"
    | "decrease"
    | "maintain";
  replyResponseTimeToThem: number;
  replyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  viewerFirstReplyResponseTimeToThem: number;
  viewerFirstReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  firstReplyResponseTimeToThem: number;
  firstReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  viewerReplyResponseTimeToThem: number;
  viewerReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
} {
  return {
    rank: personal
      ? viewerMetric("fact_table.rank", row, 3)
      : row["fact_table.rank"],
    segment: row["fact_table.segment"],
    status: row["fact_table.activity"],
    relationshipScoreTrend: row["fact_table.trend"],
    relationshipScore: row["fact_table.relationship_score_current"],
    colleaguesInteracting: personal
      ? viewerMetric("fact_table.colleague_count_current", row)
      : row["fact_table.colleague_count_current"],
    interest: row["fact_table.interest_current"],
    interestTrend: trend(
      row["fact_table.interest_current"],
      row["fact_table.interest_prev"],
    ),
    effort: row["fact_table.effort_current"],
    effortTrend: trend(
      row["fact_table.effort_current"],
      row["fact_table.effort_prev"],
    ),
    lastInteraction: safeJsonParse(
      propOr("{}", "fact_table.last_interaction_details", row),
    ),
    lastMeeting: safeJsonParse(
      propOr("{}", "fact_table.last_meeting_details", row),
    ),
    viewerInterest: viewerMetric("fact_table.interest_current", row),
    viewerRelationshipScore: viewerMetric(
      "fact_table.relationship_score_current",
      row,
    ),
    viewerRelationshipScoreTrend: viewerMetric("fact_table.trend", row, ""),
    viewerStatus: viewerMetric("fact_table.activity", row, ""),
    viewerEffort: viewerMetric("fact_table.effort_current", row),
    viewerEmailInbound: viewerMetric("fact_table.inbound_current", row),
    viewerEmailOutbound: viewerMetric("fact_table.outbound_current", row),
    viewerLastInteraction: safeJsonParse(
      pathOr("{}", ["viewer", "fact_table.last_interaction_details"], row),
    ),
    viewerLastMeeting: safeJsonParse(
      pathOr("{}", ["viewer", "fact_table.last_meeting_details"], row),
    ),
    viewerReplyResponseTimeFromThem: viewerMetric(
      "fact_table.reply_response_time_current",
      row,
    ),
    viewerReplyResponseTimeFromThemTrend: trend(
      viewerMetric("fact_table.reply_response_time_current", row),
      viewerMetric("fact_table.reply_response_time_prev", row),
    ),
    replyResponseTimeFromThem: propOr(
      0,
      "fact_table.reply_response_time_current",
      row,
    ),
    replyResponseTimeFromThemTrend: trend(
      propOr(0, "fact_table.reply_response_time_current", row),
      propOr(0, "fact_table.reply_response_time_prev", row),
    ),
    firstReplyResponseTimeFromThem: propOr(
      0,
      "fact_table.first_reply_response_time_current",
      row,
    ),
    firstReplyResponseTimeFromThemTrend: trend(
      propOr(0, "fact_table.first_reply_response_time_current", row),
      propOr(0, "fact_table.first_reply_response_time_prev", row),
    ),
    viewerFirstReplyResponseTimeFromThem: viewerMetric(
      "fact_table.first_reply_response_time_current",
      row,
    ),
    viewerFirstReplyResponseTimeFromThemTrend: trend(
      viewerMetric("fact_table.first_reply_response_time_current", row),
      viewerMetric("fact_table.first_reply_response_time_prev", row),
    ),
    replyResponseTimeToThem: propOr(
      0,
      "fact_table.reply_out_response_time_current",
      row,
    ),
    replyResponseTimeToThemTrend: trend(
      propOr(0, "fact_table.reply_out_response_time_current", row),
      propOr(0, "fact_table.reply_out_response_time_prev", row),
    ),
    viewerFirstReplyResponseTimeToThem: viewerMetric(
      "fact_table.first_reply_out_response_time_current",
      row,
    ),
    viewerFirstReplyResponseTimeToThemTrend: trend(
      viewerMetric("fact_table.first_reply_out_response_time_current", row),
      viewerMetric("fact_table.first_reply_out_response_time_prev", row),
    ),
    firstReplyResponseTimeToThem: propOr(
      0,
      "fact_table.first_reply_out_response_time_current",
      row,
    ),
    firstReplyResponseTimeToThemTrend: trend(
      propOr(0, "fact_table.first_reply_out_response_time_current", row),
      propOr(0, "fact_table.first_reply_out_response_time_prev", row),
    ),
    viewerReplyResponseTimeToThem: viewerMetric(
      "fact_table.reply_out_response_time_current",
      row,
    ),
    viewerReplyResponseTimeToThemTrend: trend(
      viewerMetric("fact_table.reply_out_response_time_current", row),
      viewerMetric("fact_table.reply_out_response_time_prev", row),
    ),
  };
}
