import { useEffect, useState } from "react";
import { useAnalytics } from "use-analytics";

import { useWatchlistWithCube } from "../useWatchlistWithCube";
import { useUpdateWatchlistMutation } from "../watchlistApi";

import Button from "components/Button";

import DustyBanana from "assets/DustyBanana.svg";
import WatchListSkeleton from "./WatchListSkeleton";
import WatchListItem from "./WatchListItem";
import WatchListModal from "./WatchListModal";
import WatchListOptionsModal from "./WatchListOptionsModal";

import useLocalStorage from "utils/hooks/useLocalStorage";
import { useGetUserQuery } from "features/profile/profileApi";
import { capitalizeFirstLetter } from "utils/string";
import {
  IWatchListItem,
  WatchListItemType,
} from "features/watchlist/watchlist.interfaces";

const WatchListView = () => {
  const [updateWatchlist] = useUpdateWatchlistMutation();
  const { track } = useAnalytics();
  const [showManageModal, setShowManageModal] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);

  const [active, setActive] = useLocalStorage("watchlist-measures", [
    "viewerRelationshipScore",
    "relationshipScore",
    "viewerLastInteraction",
    "colleagueLastInteraction",
  ]);
  const { watchlist, isLoading, isError, error } = useWatchlistWithCube(active);
  const { data: user } = useGetUserQuery();

  const fields: { field: string; label: string }[] = [
    { field: "viewerRelationshipScore", label: "Your relationship score" },
    {
      field: "relationshipScore",
      label: "All company relationship score",
    },
    {
      field: "viewerLastInteraction",
      label: "Your last interaction",
    },
    {
      field: "relationshipLength",
      label: "Length of relationship",
    },
    {
      field: "colleagueLastInteraction",
      label: "Recent colleague interaction",
    },

    {
      field: "interest",
      label: "Interest points",
    },
    {
      field: "effort",
      label: "Effort points",
    },
    {
      field: "segment",
      label: "Segment",
    },
    {
      field: "colleaguesInteracting",
      label: "# colleagues interacting",
    },
    {
      field: "viewerEmailBreakdown",
      label: "Your email breakdown",
    },
    {
      field: "allEmailBreakdown",
      label: "All email breakdown",
    },
    { field: "yourLastMeeting", label: "Your recent meeting" },
    {
      field: "colleagueLastMeeting",
      label: "Recent colleague meeting",
    },
    {
      field: "yourAverageResponseTime",
      label: "Your average response time to them",
    },
    {
      field: "allColleaguesAverageResponseTime",
      label: "All colleagues average response time to them",
    },
    {
      field: "theirAverageResponseTimeToYou",
      label: "Their average response time to you",
    },
    {
      field: "theirAverageResponseTimeToAllColleagues",
      label: "Their average response time to all colleagues",
    },
  ];

  const closeModal = () => setShowManageModal(false);
  const removeItem = async (
    entityUuid: string,
    entityType: WatchListItemType,
  ) => {
    await updateWatchlist(
      watchlist?.filter((item: any) => item.entityUuid !== entityUuid) || [],
    );
    track(`Removed ${capitalizeFirstLetter(entityType)} to watchlist`, {
      category: "Watchlist",
      label: capitalizeFirstLetter(entityType),
    });
  };

  // required for consistency
  // create empty watchlist in case none is found
  // only if not impersonating
  useEffect(() => {
    if (error && (error as any).status === 404 && !user?.impersonating) {
      updateWatchlist([]);
    }
  }, [error, updateWatchlist, user]);

  if (isError) {
    return (
      <div className="mt-20 flex justify-center">
        <div className="flex flex-col text-center">
          <i className="icon-warning-hex text-3xl text-navy"></i>
          <div className="mt-2 text-xl font-bold text-navy">
            We can't load your watchlist
          </div>
          <div className="mt-2 w-[300px] text-metal">
            Try refreshing your browser. If this issue persists, get in contact
            with our support team.
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        data-testid="watchlist-loader"
        className="mt-16 grid grid-cols-1 gap-4 px-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        <WatchListSkeleton />
        <WatchListSkeleton />
        <WatchListSkeleton />
        <WatchListSkeleton />
        <WatchListSkeleton />
        <WatchListSkeleton />
      </div>
    );
  }

  if (watchlist && watchlist.length > 0) {
    return (
      <div className="bg-gradient-to-b from-dust-light to-white">
        <div className="flex justify-between p-4">
          <Button
            icon="icon-watchlist-add"
            text="Add watchlist item"
            color="transparent"
            id="add-watchlist-item"
            onClick={() => setShowManageModal(true)}
          />
          <div className="flex gap-4">
            <Button
              icon="icon-filter-settings"
              tooltip="Watchlist options"
              color="transparent"
              onClick={() => setShowOptionsModal(true)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 px-4 pb-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {watchlist &&
            watchlist.map((item) => (
              <WatchListItem
                title={item.title}
                subtitle={item.description}
                type={item.entityType}
                kpi={item.kpi || []}
                id={item.entityUuid}
                key={item.entityUuid}
                removeItem={() => removeItem(item.entityUuid, item.entityType)}
                meta={{
                  email: item.email,
                  phoneNumbers: item.phoneNumbers,
                }}
                logoUrl={item.logoUrl}
                logoColor={item.originalBrandColour}
              />
            ))}
        </div>
        <WatchListModal
          visible={showManageModal}
          onClose={closeModal}
          watchlist={watchlist}
          onUpdate={updateWatchlist}
        />
        <WatchListOptionsModal
          visible={showOptionsModal}
          onClose={() => setShowOptionsModal(false)}
          options={fields}
          active={active}
          onUpdate={({ active }: { active: string[] }) => {
            setActive(active);
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="mt-[70px] flex flex-col items-center justify-center">
        <div className="flex items-center gap-2">
          <div className="flex h-12 w-12 items-center justify-center rounded bg-mint">
            <i className="icon-cursor text-2xl text-navy"></i>
          </div>
          <div className="flex h-16 w-16 items-center justify-center rounded bg-navy">
            <i className="icon-search text-4xl text-white"></i>
          </div>
          <div className="flex h-12 w-12 items-center justify-center rounded bg-sky">
            <i className="icon-report text-2xl text-white"></i>
          </div>
        </div>
        <div className="mt-7 text-xl text-navy">Welcome to your watchlist</div>
        <div className="mb-10 mt-2 w-[350px] text-center text-lg text-metal">
          Your Watchlist lets you add contacts and companies to your home view,
          showing you relationship data about those you care about most.
        </div>

        <Button
          icon="icon-watchlist-add"
          text="Create your watchlist"
          color="dust"
          id="create-watchlist"
          onClick={() => setShowManageModal(true)}
        />
        <div className="relative mt-20 w-full">
          <div className="sticky z-20 mx-auto w-fit">
            <WatchListItem
              type="contact"
              title="Sarah Johnstone"
              subtitle="Chief Executive Officer"
              kpi={[
                {
                  type: "progress-bar",
                  meta: {
                    title: "Relationship score",
                    value: 89,
                    color: "green",
                  },
                },
                {
                  type: "interaction",
                  meta: {
                    title: "Last interaction",
                    when: "10 days ago",
                    tag: { color: "green", label: "Active" },
                  },
                },
                {
                  type: "text",
                  meta: {
                    title: "Length of relationship",
                    label: "> 10 months",
                  },
                },
              ]}
            />
          </div>
          <img
            src={DustyBanana}
            alt="dusty banana"
            className="absolute -top-10 left-[50%] max-w-none -translate-x-[50%]"
          />
        </div>
        <WatchListModal
          visible={showManageModal}
          onClose={closeModal}
          watchlist={watchlist as IWatchListItem[]}
          onUpdate={updateWatchlist}
        />
      </div>
    );
  }
};

export default WatchListView;
