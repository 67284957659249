import { faker } from "@faker-js/faker";
import {
  IAssociatedAlias,
  ICompanyDetail,
} from "features/entity/entity.interfaces";
import { mergeRight } from "ramda";

const fakeCompany = (
  override: Partial<ICompanyDetail>,
  withLogo?: boolean,
): ICompanyDetail => {
  const probability = withLogo ? 1 : 0.5;
  const logo =
    faker.helpers.maybe(
      () => ({
        originalBrandColour: faker.helpers.maybe(() => faker.color.rgb(), {
          probability,
        }),
        logoUrl: faker.image.business(32, 32),
      }),
      { probability },
    ) || {};
  return mergeRight(
    {
      uuid: faker.datatype.uuid(),
      displayName: faker.company.name(),
      businessId: faker.datatype.uuid(),
      flags: [],
      ...logo,
      associatedAliases: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        () => ({
          name: faker.internet.domainName(),
          aliasType: faker.helpers.arrayElement(["ed", "ea"]),
          isPrimaryAlias: faker.datatype.boolean(),
        }),
      ) as IAssociatedAlias[],
      summary: faker.helpers.maybe(() => faker.company.catchPhrase()),
      sector: faker.helpers.maybe(() => faker.commerce.department()),
      industryGroup: faker.helpers.maybe(() => faker.company.catchPhrase()),
      industry: faker.helpers.maybe(() => faker.company.bsAdjective()),
      marketCap: faker.helpers.maybe(() =>
        faker.helpers.arrayElement(["Small Cap", "Mid Cap", "Large Cap"]),
      ),
      founded: faker.helpers.maybe(() => faker.date.past(100).getFullYear()),
      location: faker.helpers.maybe(
        () =>
          faker.address.city() +
          ", " +
          faker.address.state() +
          ", " +
          faker.address.country(),
      ),
      latitude: faker.helpers.maybe(() => faker.address.latitude()),
      longitude: faker.helpers.maybe(() => faker.address.longitude()),
      companyType: faker.helpers.maybe(() =>
        faker.helpers.arrayElement([
          "Public Company",
          "Private Company",
          "Startup",
        ]),
      ),
      companySize: faker.helpers.maybe(() =>
        faker.helpers.arrayElement([
          "1-10",
          "11-50",
          "51-200",
          "201-500",
          "500+",
        ]),
      ),
      slogan: faker.helpers.maybe(() => faker.company.catchPhrase()),
      linkedinUrl: faker.helpers.maybe(
        () => "linkedin.com/company/" + faker.internet.domainWord(),
      ),
      equities: Array.from(
        { length: faker.datatype.number({ min: 0, max: 10 }) },
        () => ({
          uuid: faker.datatype.uuid(),
          symbol: faker.finance.currencyCode(),
          currency: faker.finance.currencyCode(),
          exchange: faker.helpers.arrayElement([
            "NYSE",
            "NASDAQ",
            "LSE",
            "TSE",
          ]),
          market: faker.helpers.arrayElement([
            "New York Stock Exchange",
            "London Stock Exchange",
            "Tokyo Stock Exchange",
          ]),
          isin: faker.datatype.uuid().slice(0, 12),
          cusip: faker.datatype.uuid().slice(0, 9),
        }),
      ),
    },
    override,
  );
};

export default fakeCompany;
