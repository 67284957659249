import { useCubeQuery } from "@cubejs-client/react";
import { Filter } from "@cubejs-client/core";
import { IUser } from "features/profile/user.interface";
import { prop, propOr } from "ramda";

const useEntityActivity = ({
  contactId,
  companyId,
  user,
  additionalFilters,
}: {
  contactId?: string;
  companyId?: string;
  user?: IUser;
  additionalFilters: Filter[];
}) => {
  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };

  /* Global data */
  const { isLoading: isGlobalLoading, meeting: globalMeeting } =
    useLastActivityData([
      ...entityFilters(),
      ...additionalFilters,
      {
        member: "dim_user.email",
        operator: "notEquals",
        values: [user ? (user.email as string) : ""],
      },
    ]);

  const {
    date: lastGlobalOutbound,
    mailbox: lastMailboxOutbound,
    isLoading: isGlobalOutboundLoading,
  } = useLastEmail({
    inbound: false,
    filters: [
      ...entityFilters(),
      ...additionalFilters,
      {
        member: "dim_user.email",
        operator: "notEquals",
        values: [user ? (user.email as string) : ""],
      },
    ],
  });

  /* Viewers data */
  const { isLoading: isViewerLoading, meeting: viewerMeeting } =
    useLastActivityData([
      ...entityFilters(),
      ...additionalFilters,
      {
        member: "dim_user.email",
        operator: "equals",
        values: [user ? (user.email as string) : ""],
      },
    ]);

  const { date: lastInbound, isLoading: isInboundLoading } = useLastEmail({
    inbound: true,
    filters: [
      ...entityFilters(),
      ...additionalFilters,
      {
        member: "dim_user.email",
        operator: "equals",
        values: [user ? (user.email as string) : ""],
      },
    ],
  });

  const { date: lastOutbound, isLoading: isOutboundLoading } = useLastEmail({
    inbound: false,
    filters: [
      ...entityFilters(),
      ...additionalFilters,
      {
        member: "dim_user.email",
        operator: "equals",
        values: [user ? (user.email as string) : ""],
      },
    ],
  });

  return {
    viewer: {
      lastInbound,
      lastOutbound,
      lastMeeting: prop("date", viewerMeeting),
    },
    global: {
      lastGlobalOutbound,
      lastMailboxOutbound,
      lastGlobalMeeting: globalMeeting,
    },
    isLoading:
      isGlobalLoading ||
      isGlobalOutboundLoading ||
      isOutboundLoading ||
      isInboundLoading ||
      isViewerLoading,
  };
};

const useLastEmail = ({
  filters,
  inbound,
}: {
  filters: Filter[];
  inbound: boolean;
}) => {
  const { resultSet, isLoading } = useCubeQuery({
    dimensions: [
      "dim_email.mailbox_full_name",
      "dim_email.message_sent_date_time",
    ],
    order: [["dim_email.message_sent_date_time", "desc"]],
    filters: [
      ...filters,
      {
        member: "fact_email.is_inbound",
        operator: "equals",
        values: [inbound ? "true" : "false"],
      },
    ],
    limit: 1,
  });

  const result = resultSet?.tablePivot() || [];
  const row = result.length ? result[0] : {};

  const date = prop("dim_email.message_sent_date_time", row);
  const mailbox = prop("dim_email.mailbox_full_name", row);

  return { date, mailbox, isLoading };
};

const useLastActivityData = (filters: Filter[]) => {
  const { resultSet, isLoading } = useCubeQuery({
    measures: [
      "fact_table.last_interaction_details",
      "fact_table.last_meeting_details",
    ],
    filters,
  });

  const result = resultSet?.tablePivot() || [];
  const row = result.length ? result[0] : {};

  const interaction = JSON.parse(
    propOr("{}", "fact_table.last_interaction_details", row),
  );
  const meeting = JSON.parse(
    propOr("{}", "fact_table.last_meeting_details", row),
  );

  return { isLoading, interaction, meeting };
};

export default useEntityActivity;
