import classnames from "classnames";
import ColleagueIdentifier from "assets/ColleagueIdentifier.svg";

export type IdentifierType = "company" | "contact" | "colleague" | "team";
export type IdentifierSize = "large" | "normal" | "small" | "tiny";

const Identifier = ({
  text,
  color,
  logoUrl,
  type,
  size = "normal",
}: {
  text: string;
  color?: string;
  type: IdentifierType;
  logoUrl?: string;
  size?: IdentifierSize;
}) => {
  const logoSize =
    size === "large" ? 48 : size === "normal" ? 32 : size === "small" ? 24 : 16;
  if (type === "contact") {
    return (
      <div
        className={classnames(
          "flex items-center justify-center rounded-full bg-gradient-to-b from-metal to-metal-dark text-white",
          {
            "h-12 w-12": size === "large",
            "h-8 w-8 text-[11px]": size === "normal",
            "h-6 w-6 text-[9px]": size === "small",
            "h-4 w-4 text-[7px]": size === "tiny",
          },
        )}
      >
        {size !== "tiny" && text}
      </div>
    );
  } else if (type === "colleague") {
    return (
      <div className={classnames("relative")}>
        <img
          className={classnames({
            "h-12 w-12": size === "large",
            "h-8 w-8 text-[11px]": size === "normal",
            "h-6 w-6 text-[9px]": size === "small",
            "h-4 w-4 text-[7px]": size === "tiny",
          })}
          src={ColleagueIdentifier}
          alt="colleague-identifier"
        />
        {size !== "tiny" && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-sm font-bold text-mint-darkest">
            {text}
          </div>
        )}
      </div>
    );
  } else if (type === "company") {
    return !!logoUrl ? (
      <div
        className={classnames("overflow-hidden rounded-sm", {
          "h-12 w-12": size === "large",
          "h-8 w-8": size === "normal",
          "h-6 w-6": size === "small",
          "h-4 w-4": size === "tiny",
        })}
      >
        <img
          width={logoSize}
          height={logoSize}
          src={logoUrl}
          className="bg-white"
          alt="logo"
        />
      </div>
    ) : (
      <div
        className={classnames(
          "flex items-center justify-center rounded-sm bg-dust text-metal",
          {
            "h-12 w-12": size === "large",
            "h-8 w-8 text-[11px]": size === "normal",
            "h-6 w-6 text-[9px]": size === "small",
            "h-4 w-4 text-[7px]": size === "tiny",
          },
        )}
      >
        {size !== "tiny" && text}
      </div>
    );
  } else if (type === "team") {
    return (
      <div
        style={{ background: color }}
        className={classnames(
          "flex h-4 w-4 items-center justify-center rounded-full text-white",
          {
            "bg-metal-dark": !color,
          },
        )}
      ></div>
    );
  }

  return null;
};

export default Identifier;
