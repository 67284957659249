const trend = (
  current: any,
  prev: any,
): "increase" | "decrease" | "maintain" => {
  const currentValue = Number(current) || 0;
  const previousValue = Number(prev) || 0;
  const delta = currentValue - previousValue;
  if (currentValue > 0 && delta > 0) return "increase";
  if (currentValue > 0 && delta < 0) return "decrease";
  return "maintain";
};

export default trend;
