import classNames from "classnames";
import Button from "components/Button";
import { format } from "date-fns";
import { IInteraction } from "features/interaction/interaction.interface";
import { deviceToTypeIcon, deviceToPlatformIcon } from "utils/devices";
import { highlightText } from "utils/highlightText";
import { SearchTargetType } from "./InteractionSearch";
import InteractionTimelineTag from "./InteractionTimelineTag";

const Interaction = ({
  interaction,
  searchValue,
  searchTarget,
  variant = "default",
  onClick,
  dateFormat = "HH:mm",
}: {
  interaction: IInteraction;
  searchTarget?: SearchTargetType;
  searchValue?: string;
  variant?: "default" | "link-tracking" | "email-detail";
  onClick?: () => void;
  dateFormat?: string;
}) => {
  return (
    <div
      tabIndex={0}
      onClick={onClick}
      className={classNames(
        "group mx-4 flex flex-row gap-x-2 text-base text-metal",
        {
          "z-[5]": variant === "email-detail",
          "cursor-pointer": variant !== "email-detail",
        },
      )}
      data-testid="interaction"
    >
      <div
        className={classNames(
          "flex min-w-0 flex-row items-center gap-x-2 rounded-md border border-dust bg-white p-2 hover:border-dust-darker hover:shadow sm:h-10 sm:gap-x-4 sm:py-0",
          {
            "sm:py-1 sm:pl-2 sm:pr-1": interaction.url,
          },
        )}
      >
        <InteractionTimelineTag
          size={variant === "email-detail" ? "xs" : "large"}
          interaction={interaction}
          squeeze
        />
        <div className="flex min-w-0 flex-col gap-y-0.5 gap-x-4 sm:flex-row sm:items-center">
          <div className="flex flex-row items-center gap-4 whitespace-nowrap">
            <div className="font-bold text-metal-dark">
              {searchTarget !== "messageSubject"
                ? highlightText(interaction.contact.fullName, searchValue)
                : interaction.contact.fullName}
            </div>
            {variant !== "email-detail" && !!interaction.enhancedEvent && (
              <div className="text-metal">{interaction.enhancedEvent.verb}</div>
            )}
          </div>
          {variant !== "email-detail" && (
            <div className="block w-full truncate font-bold text-metal-dark">
              {searchTarget !== "fullName"
                ? highlightText(interaction.messageSubject, searchValue)
                : interaction.messageSubject}
            </div>
          )}
          {interaction.url && (
            <div className="hidden sm:flex">
              <Button
                color="transparent"
                tooltip="Open link in new tab"
                icon="icon-popup"
                onClick={() => window.open(interaction.url, "_blank")}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames(
          "flex flex-row items-center gap-2 whitespace-nowrap",
          { "hidden md:flex": variant === "link-tracking" },
        )}
      >
        {format(new Date(interaction.actionCreationTimestamp), dateFormat)}
        <i
          className={classNames(
            "invisible text-lg group-hover:visible",
            deviceToTypeIcon(interaction.deviceType),
          )}
        />
        <i
          className={classNames(
            "invisible text-lg group-hover:visible",
            deviceToPlatformIcon(interaction.deviceType),
          )}
        />
      </div>
    </div>
  );
};

export default Interaction;
