import classNames from "classnames";
import { mergeRight, pathOr, prop, propOr } from "ramda";
import { useRef, useState } from "react";
import { formatSeconds } from "utils/dates";

import { formatDifferenceFromNow } from "utils/dates";
import flipSorting from "utils/flipSorting";
import { trendColors, activityColors } from "utils/colorMapping";

import Header from "./Header";
import TableSkeletonRow from "./TableSkeletonRow";
import { ITableField, SortType } from "./Table.interfaces";
import { IdentifierCell } from "./Cells";
import { GenericCell, Row } from "./Table";
import useMediaQuery from "utils/hooks/useMediaQuery";
import {
  ActivityType,
  RankType,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";
import { extractSharedMetrics } from "./shared";

const CompanyRow = ({
  fields,
  onlyIdentifier,
  companyUuid,
  companyName,
  companyLogoUrl,
  companySector,
  companyIndustry,
  companyType,
  companySize,
  rank,
  segment,
  status,
  relationshipScore,
  relationshipScoreTrend,
  colleaguesInteracting,
  interest,
  interestTrend,
  effort,
  effortTrend,
  lastInteraction,
  lastMeeting,
  emailInbound,
  emailOutbound,
  personal,
  viewerStatus,
  viewerInterest,
  viewerEffort,
  viewerRelationshipScore,
  viewerRelationshipScoreTrend,
  viewerLastInteraction,
  viewerLastMeeting,
  viewerEmailInbound,
  viewerEmailOutbound,
  contacts,
  newContacts,
  activeContacts,
  meetings,
  meetingsDuration,
  responseTime,
  responseTimeOut,
  viewerReplyResponseTimeFromThem,
  viewerReplyResponseTimeFromThemTrend,
  replyResponseTimeFromThem,
  replyResponseTimeFromThemTrend,
  firstReplyResponseTimeFromThem,
  firstReplyResponseTimeFromThemTrend,
  viewerFirstReplyResponseTimeFromThem,
  viewerFirstReplyResponseTimeFromThemTrend,
  replyResponseTimeToThem,
  replyResponseTimeToThemTrend,
  viewerFirstReplyResponseTimeToThem,
  viewerFirstReplyResponseTimeToThemTrend,
  firstReplyResponseTimeToThem,
  firstReplyResponseTimeToThemTrend,
  viewerReplyResponseTimeToThem,
  viewerReplyResponseTimeToThemTrend,
  isServiceProvider,
}: {
  fields: any[];
  onlyIdentifier: boolean;
  companyUuid: string;
  companyName: string;
  companyLogoUrl?: string;
  companySector?: string;
  companyIndustry?: string;
  companyType?: string;
  companySize?: string;
  rank: string;
  segment: string;
  status: string;
  relationshipScore: number;
  relationshipScoreTrend: string;
  colleaguesInteracting: number;
  interest: number;
  interestTrend: "increase" | "decrease" | "maintain";
  effort: number;
  effortTrend: "increase" | "decrease" | "maintain";
  lastInteraction: { date: string; contact: string; colleague: string };
  lastMeeting: { date: string; contact: string; colleague: string };
  emailInbound: number;
  emailOutbound: number;
  personal: boolean;
  viewerStatus: string;
  viewerInterest: number;
  viewerEffort: number;
  viewerRelationshipScore: number;
  viewerRelationshipScoreTrend: string;
  viewerLastInteraction: { date: string; contact: string; colleague: string };
  viewerLastMeeting: { date: string; contact: string; colleague: string };
  viewerEmailInbound: number;
  viewerEmailOutbound: number;
  contacts: number;
  newContacts: number;
  activeContacts: number;
  meetings: number;
  meetingsDuration: number;
  responseTime: number;
  responseTimeOut: number;
  viewerReplyResponseTimeFromThem: number;
  viewerReplyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  replyResponseTimeFromThem: number;
  replyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  firstReplyResponseTimeFromThem: number;
  firstReplyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  viewerFirstReplyResponseTimeFromThem: number;
  viewerFirstReplyResponseTimeFromThemTrend:
    | "increase"
    | "decrease"
    | "maintain";
  replyResponseTimeToThem: number;
  replyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  viewerFirstReplyResponseTimeToThem: number;
  viewerFirstReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  firstReplyResponseTimeToThem: number;
  firstReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  viewerReplyResponseTimeToThem: number;
  viewerReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  isServiceProvider: boolean;
}) => {
  const cells: any[] = [];
  const getStatusLabel = (value: string) =>
    +value !== ActivityType.Undefined && value !== ""
      ? ActivityType[+value]
      : "";

  cells.push(
    <IdentifierCell
      id={companyUuid}
      type="company"
      title={companyName}
      logoUrl={companyLogoUrl}
      flags={{ isServiceProvider }}
      meta={{
        onlyIdentifier,
      }}
    />,
  );
  let metaFromField: any = {
    employees: {
      text: companySize,
    },
    sector: {
      text: companySector,
    },
    industry: {
      text: companyIndustry,
    },
    companyType: {
      text: companyType,
    },
    interest: {
      trend: interestTrend,
      value: interest,
      secondaryValue: viewerInterest,
      secondaryLabel: personal ? "All" : "You",
    },
    effort: {
      trend: effortTrend,
      value: effort,
      secondaryValue: viewerEffort,
      secondaryLabel: personal ? "All" : "You",
    },
    rank: {
      color: RankType[+rank] && RankType[+rank].toLocaleLowerCase(),
      label: +rank === RankType.Unranked ? "" : RankType[+rank],
    },
    segment: { text: SegmentType[+segment] },
    colleaguesInteracting: { text: colleaguesInteracting },
    numberContacts: { text: contacts },
    numberActiveContacts: { text: activeContacts },
    numberNewContacts: { text: newContacts },
    numberMeetings: { text: meetings },
    emailsSent: { text: emailOutbound },
    emailsReceived: { text: emailInbound },
    durationMeetings: { text: formatSeconds(meetingsDuration) },
    avgResponseTime: { text: formatSeconds(responseTime) },
    avgResponseTimeOut: { text: formatSeconds(responseTimeOut) },
  };

  if (personal) {
    metaFromField = mergeRight(metaFromField, {
      relationshipScore: {
        viewer: {
          value: relationshipScore,
          description: TrendType[+relationshipScoreTrend],
          color: propOr("green", relationshipScoreTrend, trendColors),
        },
      },
      viewerLastInteraction: {
        when:
          lastInteraction.date &&
          formatDifferenceFromNow(new Date(lastInteraction.date)),
        tagColor: propOr("green", status, activityColors),
        tagLabel: getStatusLabel(status),
      },
      colleagueLastInteraction: {
        when:
          viewerLastInteraction.date &&
          formatDifferenceFromNow(new Date(viewerLastInteraction.date)),
        who: viewerLastInteraction.colleague,
        tagColor: propOr(undefined, viewerStatus, activityColors),
        tagLabel: getStatusLabel(viewerStatus),
      },
      viewerEmailBreakdown: {
        emailsIn: emailInbound,
        emailsOut: emailOutbound,
      },
      allEmailBreakdown: {
        emailsIn: viewerEmailInbound,
        emailsOut: viewerEmailOutbound,
      },
      viewerLastMeeting: {
        when:
          lastMeeting.date &&
          formatDifferenceFromNow(new Date(lastMeeting.date)),
      },
      colleagueLastMeeting: {
        when:
          viewerLastMeeting.date &&
          formatDifferenceFromNow(new Date(viewerLastMeeting.date)),
        who: viewerLastMeeting.colleague,
      },
      yourAverageResponseTime: {
        trends: [
          {
            value: viewerReplyResponseTimeToThem,
            trend: viewerReplyResponseTimeToThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: viewerFirstReplyResponseTimeToThem,
            trend: viewerFirstReplyResponseTimeToThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      allColleaguesAverageResponseTime: {
        trends: [
          {
            value: replyResponseTimeToThem,
            trend: replyResponseTimeToThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: firstReplyResponseTimeToThem,
            trend: firstReplyResponseTimeToThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      theirAverageResponseTimeToYou: {
        trends: [
          {
            value: viewerReplyResponseTimeFromThem,
            trend: viewerReplyResponseTimeFromThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: viewerFirstReplyResponseTimeFromThem,
            trend: viewerFirstReplyResponseTimeFromThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      theirAverageResponseTimeToAllColleagues: {
        trends: [
          {
            value: replyResponseTimeFromThem,
            trend: replyResponseTimeFromThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: firstReplyResponseTimeFromThem,
            trend: firstReplyResponseTimeFromThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
    });
  } else {
    metaFromField = mergeRight(metaFromField, {
      relationshipScore: {
        global: {
          value: relationshipScore,
          description: TrendType[+relationshipScoreTrend],
          color: propOr("green", relationshipScoreTrend, trendColors),
        },
        viewer: {
          value: viewerRelationshipScore,
          description: TrendType[+viewerRelationshipScoreTrend],
          color: propOr("green", viewerRelationshipScoreTrend, trendColors),
        },
      },
      viewerLastInteraction: {
        when:
          viewerLastInteraction.date &&
          formatDifferenceFromNow(new Date(viewerLastInteraction.date)),
        tagColor: propOr(undefined, viewerStatus, activityColors),
        tagLabel: getStatusLabel(viewerStatus),
      },
      colleagueLastInteraction: {
        when:
          lastInteraction.date &&
          formatDifferenceFromNow(new Date(lastInteraction.date)),
        who: lastInteraction.colleague,
        tagColor: propOr("green", status, activityColors),
        tagLabel: getStatusLabel(status),
      },
      viewerEmailBreakdown: {
        emailsIn: viewerEmailInbound,
        emailsOut: viewerEmailOutbound,
      },
      allEmailBreakdown: {
        emailsIn: emailInbound,
        emailsOut: emailOutbound,
      },
      viewerLastMeeting: {
        when:
          viewerLastMeeting.date &&
          formatDifferenceFromNow(new Date(viewerLastMeeting.date)),
        who: viewerLastMeeting.colleague,
      },
      colleagueLastMeeting: {
        when:
          lastMeeting.date &&
          formatDifferenceFromNow(new Date(lastMeeting.date)),
        who: lastMeeting.colleague,
      },
      yourAverageResponseTime: {
        trends: [
          {
            value: viewerReplyResponseTimeToThem,
            trend: viewerReplyResponseTimeToThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: viewerFirstReplyResponseTimeToThem,
            trend: viewerFirstReplyResponseTimeToThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      allColleaguesAverageResponseTime: {
        trends: [
          {
            value: replyResponseTimeToThem,
            trend: replyResponseTimeToThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: firstReplyResponseTimeToThem,
            trend: firstReplyResponseTimeToThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      theirAverageResponseTimeToYou: {
        trends: [
          {
            value: viewerReplyResponseTimeFromThem,
            trend: viewerReplyResponseTimeFromThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: viewerFirstReplyResponseTimeFromThem,
            trend: viewerFirstReplyResponseTimeFromThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      theirAverageResponseTimeToAllColleagues: {
        trends: [
          {
            value: replyResponseTimeFromThem,
            trend: replyResponseTimeFromThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: firstReplyResponseTimeFromThem,
            trend: firstReplyResponseTimeFromThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
    });
  }

  fields.forEach((option) => {
    cells.push(
      <GenericCell
        type={option.type}
        meta={prop(option.field, metaFromField)}
      />,
    );
  });
  return <Row cells={cells} />;
};

const CompanyTable = ({
  dataSource = null,
  fields,
  isLoading,
  personal,
  nameSource,
  sorting,
  setSorting,
}: {
  dataSource: Array<object> | null;
  fields: ITableField[];
  isLoading: boolean;
  personal: boolean;
  nameSource: string;
  sorting: string | null;
  setSorting: (value: any) => void;
}) => {
  const sortingState = (field?: string): SortType | undefined => {
    if (!sorting) {
      return;
    }
    if (field && sorting.endsWith(field)) {
      return sorting.startsWith("-") ? "desc" : "asc";
    }
  };
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const tableRef = useRef(null);
  const [minifyFirstColumn, setMinifyFirstColumn] = useState(false);
  const handleScroll = () => {
    const table = tableRef.current as HTMLTableElement | null;
    if (table !== null) {
      const scrollLeft = table.scrollLeft;
      const maxScroll = table.scrollWidth - table.clientWidth;

      if (
        !isDesktop &&
        scrollLeft > 100 &&
        !minifyFirstColumn &&
        maxScroll > 300
      ) {
        setMinifyFirstColumn(true);
      } else if (scrollLeft <= 100 && minifyFirstColumn) {
        setMinifyFirstColumn(false);
      }
    }
  };

  return (
    <div
      className="overflow-x-auto whitespace-nowrap"
      onScroll={handleScroll}
      ref={tableRef}
    >
      <table className="w-full border-separate">
        <thead>
          <tr>
            <Header
              text={minifyFirstColumn ? "" : "Company name"}
              sortable={!!sorting}
              sticky
              sortState={sortingState(nameSource)}
              onSort={() => setSorting(flipSorting(nameSource, sorting || ""))}
            />
            {fields.map((option) => (
              <Header
                text={option.label}
                sortable={!!option.source && !!sorting}
                sortState={sortingState(option.source)}
                key={"header-" + option.field}
                onSort={() =>
                  setSorting(flipSorting(option.source || "", sorting || ""))
                }
              />
            ))}
          </tr>
        </thead>
        <tbody className={classNames({ "animate-pulse": isLoading })}>
          {isLoading ? (
            <>
              {Array(10)
                .fill(null)
                .map((_, i) => (
                  <TableSkeletonRow fields={fields} key={i} />
                ))}
            </>
          ) : (
            <>
              {dataSource?.map((row: any) => (
                <CompanyRow
                  fields={fields}
                  personal={personal}
                  onlyIdentifier={minifyFirstColumn}
                  companyUuid={row["dim_company.uuid"]}
                  companyName={row["dim_company.display_name"]}
                  companyLogoUrl={row["dim_company.logo_url"]}
                  companySector={row["dim_company.sector"]}
                  companyIndustry={row["dim_company.industry"]}
                  companyType={row["dim_company.company_type"]}
                  companySize={row["dim_company.company_size"]?.replace(
                    "employees",
                    "",
                  )}
                  isServiceProvider={row["dim_company.is_service_provider"]}
                  key={row["dim_company.uuid"]}
                  emailInbound={
                    row["fact_table.inbound_current"] ||
                    row["fact_email.inbound"]
                  }
                  emailOutbound={
                    row["fact_table.outbound_current"] ||
                    row["fact_email.outbound"]
                  }
                  contacts={
                    personal
                      ? pathOr(0, ["viewer", "dim_contact.count"], row)
                      : row["dim_contact.count"]
                  }
                  newContacts={
                    personal
                      ? pathOr(0, ["viewer", "new_contact.count"], row)
                      : row["new_contact.count"]
                  }
                  activeContacts={
                    personal
                      ? pathOr(0, ["viewer", "active_contact.count"], row)
                      : row["active_contact.count"]
                  }
                  meetings={
                    personal
                      ? pathOr(0, ["viewer", "dim_calendar.count"], row)
                      : row["dim_calendar.count"]
                  }
                  meetingsDuration={
                    personal
                      ? pathOr(0, ["viewer", "dim_calendar.duration"], row)
                      : row["dim_calendar.duration"]
                  }
                  responseTime={row["fact_email.avg_reply_response_time"]}
                  responseTimeOut={
                    row["fact_email.avg_reply_out_response_time"]
                  }
                  {...extractSharedMetrics(row, personal)}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyTable;
