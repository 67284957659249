import { pathOr, propOr } from "ramda";
import { RankType, SegmentType } from "features/entity/entity.interfaces";
import downloadCSV from "utils/downloadCSV";
import { ITableField } from "components/table/Table.interfaces";
import { safeJsonParse } from "utils/string";

const viewerMetric = (metric: string, row: any) =>
  pathOr(0, ["viewer", metric], row);

const handleExportCompanies = ({
  rows,
  fields,
  personal,
}: {
  rows: object[];
  fields: ITableField[];
  personal: boolean;
}) => {
  const exportData = rows?.map((row: any) => {
    const selectedData: { [key: string]: string | number } = {};
    selectedData["Company Name"] = row["dim_company.display_name"];
    const lastInteraction = safeJsonParse(
      propOr("{}", "fact_table.last_interaction_details", row),
    );
    const viewerLastInteraction = safeJsonParse(
      pathOr("{}", ["viewer", "fact_table.last_interaction_details"], row),
    );
    const lastMeeting = safeJsonParse(
      propOr("{}", "fact_table.last_meeting_details", row),
    );
    const viewerLastMeeting = safeJsonParse(
      pathOr("{}", ["viewer", "fact_table.last_meeting_details"], row),
    );
    fields.forEach((field) => {
      switch (field.field) {
        case "rank":
          const rank = personal
            ? pathOr(3, ["viewer", "fact_table.rank"], row)
            : row["fact_table.rank"];
          selectedData[field.label] =
            +rank === RankType.Unranked ? "" : RankType[+rank];
          break;
        case "relationshipScore":
          selectedData[field.label] = personal
            ? viewerMetric("fact_table.relationship_score_current", row)
            : row["fact_table.relationship_score_current"];
          break;
        case "interest":
          selectedData[field.label] = personal
            ? viewerMetric("fact_table.interest_current", row)
            : row["fact_table.interest_current"];
          selectedData[`Past ${field.label}`] = personal
            ? viewerMetric("fact_table.interest_prev", row)
            : row["fact_table.interest_prev"];
          break;
        case "effort":
          selectedData[field.label] = personal
            ? viewerMetric("fact_table.effort_current", row)
            : row["fact_table.effort_current"];
          selectedData[`Past ${field.label}`] = personal
            ? viewerMetric("fact_table.effort_prev", row)
            : row["fact_table.effort_prev"];
          break;
        case "segment":
          selectedData[field.label] =
            SegmentType[
              +(personal
                ? viewerMetric("fact_table.segment", row)
                : row["fact_table.segment"])
            ];
          break;
        case "viewerLastInteraction":
          selectedData[`${field.label} Date`] =
            (personal ? lastInteraction : viewerLastInteraction)?.date || "";
          break;
        case "colleagueLastInteraction":
          selectedData[`${field.label} Date`] =
            (personal ? viewerLastInteraction : lastInteraction)?.date || "";
          selectedData[`${field.label} Colleague`] =
            (personal ? viewerLastInteraction : lastInteraction)?.colleague ||
            "";
          break;
        case "colleaguesInteracting":
          selectedData[field.label] = personal
            ? viewerMetric("fact_table.colleague_count_current", row)
            : row["fact_table.colleague_count_current"];
          break;
        case "viewerEmailBreakdown":
          selectedData[`${field.label} inbound`] = personal
            ? row["fact_table.inbound_current"]
            : viewerMetric("fact_table.inbound_current", row);
          selectedData[`${field.label} outbound`] = personal
            ? row["fact_table.outbound_current"]
            : viewerMetric("fact_table.outbound_current", row);
          break;
        case "allEmailBreakdown":
          selectedData[`${field.label} inbound`] = personal
            ? viewerMetric("fact_table.interest_current", row)
            : row["fact_table.inbound_current"];
          selectedData[`${field.label} outbound`] = personal
            ? viewerMetric("fact_table.outbound_current", row)
            : row["fact_table.outbound_current"];
          break;
        case "viewerLastMeeting":
          selectedData[field.label] =
            (personal ? lastMeeting : viewerLastMeeting)?.date || "";
          break;
        case "colleagueLastMeeting":
          selectedData[field.label] =
            (personal ? viewerLastMeeting : lastMeeting)?.date || "";
          break;
        case "yourAverageResponseTime":
          selectedData[`${field.label} (All) minutes`] = personal
            ? row["fact_table.reply_response_time_current"]
            : viewerMetric("fact_table.reply_response_time_current", row);
          //selectedData[`${field.label} (First) minutes`] = personal
          //  ? row["fact_table.first_reply_response_time_current"]
          //  : viewerMetric("fact_table.first_reply_response_time_current", row);
          selectedData[`${field.label} (All-Past) minutes`] = personal
            ? row["fact_table.reply_response_time_prev"]
            : viewerMetric("fact_table.reply_response_time_prev", row);
          //selectedData[`${field.label} (First-Past) minutes`] = personal
          //  ? row["fact_table.first_reply_response_time_prev"]
          //  : viewerMetric("fact_table.first_reply_response_time_prev", row);

          break;
        case "allColleaguesAverageResponseTime":
          selectedData[`${field.label} (All) minutes`] = personal
            ? viewerMetric("fact_table.reply_response_time_current", row)
            : row["fact_table.reply_response_time_current"];
          //selectedData[`${field.label} (First) minutes`] = personal
          //  ? viewerMetric("fact_table.first_reply_response_time_current", row)
          //  : row["fact_table.first_reply_response_time_current"];
          selectedData[`${field.label} (All-Past) minutes`] = personal
            ? viewerMetric("fact_table.reply_response_time_prev", row)
            : row["fact_table.reply_response_time_prev"];
          //selectedData[`${field.label} (First-Past) minutes`] = personal
          //  ? viewerMetric("fact_table.first_reply_response_time_prev", row)
          //  : row["fact_table.first_reply_response_time_prev"];
          break;
        case "theirAverageResponseTimeToYou":
          selectedData[`${field.label} (All) minutes`] = personal
            ? row["fact_table.reply_out_response_time_current"]
            : viewerMetric("fact_table.reply_out_response_time_current", row);
          //selectedData[`${field.label} (First) minutes`] = personal
          //  ? row["fact_table.first_reply_out_response_time_current"]
          //  : viewerMetric("fact_table.first_reply_out_response_time_current", row);
          selectedData[`${field.label} (All-Past) minutes`] = personal
            ? row["fact_table.reply_out_response_time_prev"]
            : viewerMetric("fact_table.reply_out_response_time_prev", row);
          //selectedData[`${field.label} (First-Past) minutes`] = personal
          //  ? row["fact_table.first_reply_out_response_time_prev"]
          //  : viewerMetric("fact_table.first_reply_out_response_time_prev", row,);
          break;
        case "theirAverageResponseTimeToAllColleagues":
          selectedData[`${field.label} (All) minutes`] = personal
            ? viewerMetric("fact_table.reply_out_response_time_current", row)
            : row["fact_table.reply_out_response_time_current"];
          //selectedData[`${field.label} (First) minutes`] = personal
          //  ? viewerMetric(
          //      "fact_table.first_reply_out_response_time_current", row)
          //  : row["fact_table.first_reply_out_response_time_current"];
          selectedData[`${field.label} (All-Past) minutes`] = personal
            ? viewerMetric("fact_table.reply_out_response_time_prev", row)
            : row["fact_table.reply_out_response_time_prev"];
          //selectedData[`${field.label} (First-Past) minutes`] = personal
          //  ? viewerMetric("fact_table.first_reply_out_response_time_prev", row)
          //  : row["fact_table.first_reply_out_response_time_prev"];
          break;
        case "companySize":
          selectedData[field.label] = row["dim_company.company_size"]?.replace(
            "employees",
            "",
          );
          break;
        case "sector":
          selectedData[field.label] = row["dim_company.sector"];
          break;
        case "industry":
          selectedData[field.label] = row["dim_company.industry"];
          break;
        case "companyType":
          selectedData[field.label] = row["dim_company.company_type"];
          break;
        default:
          break;
      }
    });

    return selectedData;
  });

  const title = `companies_export_${Date.now()}`
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, " ");
  downloadCSV(exportData as { [key: string]: string | number }[], title);
};

export default handleExportCompanies;
