import { head, mergeRight, prop, propOr } from "ramda";
import classNames from "classnames";
import { useRef, useState } from "react";

import { formatDifferenceFromNow } from "utils/dates";
import { trendColors, activityColors } from "utils/colorMapping";

import Header from "./Header";
import TableSkeletonRow from "./TableSkeletonRow";

import { ITableField, SortType } from "./Table.interfaces";
import { IdentifierCell } from "./Cells";
import { Row, GenericCell } from "./Table";
import flipSorting from "utils/flipSorting";
import useMediaQuery from "utils/hooks/useMediaQuery";
import {
  ActivityType,
  RankType,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";
import { extractSharedMetrics } from "./shared";

const firstPhoneNumber = (phones?: any[]): string =>
  propOr("", "phone_number", head(phones || []));

const firstRole = (roles?: string[]): string => head(roles || []) || "";

const ContactRow = ({
  fields,
  onlyIdentifier,
  contactUuid,
  contactName,
  contactEmail,
  companyName,
  isLeaver,
  isAssistant,
  status,
  rank,
  roles,
  phoneNumbers,
  segment,
  relationshipScore,
  relationshipScoreTrend,
  colleaguesInteracting,
  interest,
  interestTrend,
  effort,
  effortTrend,
  lastInteraction,
  lastMeeting,
  emailInbound,
  emailOutbound,
  personal,
  viewerStatus,
  viewerInterest,
  viewerEffort,
  viewerRelationshipScore,
  viewerRelationshipScoreTrend,
  viewerLastInteraction,
  viewerLastMeeting,
  viewerEmailInbound,
  viewerEmailOutbound,
  viewerReplyResponseTimeFromThem,
  viewerReplyResponseTimeFromThemTrend,
  replyResponseTimeFromThem,
  replyResponseTimeFromThemTrend,
  firstReplyResponseTimeFromThem,
  firstReplyResponseTimeFromThemTrend,
  viewerFirstReplyResponseTimeFromThem,
  viewerFirstReplyResponseTimeFromThemTrend,
  replyResponseTimeToThem,
  replyResponseTimeToThemTrend,
  viewerFirstReplyResponseTimeToThem,
  viewerFirstReplyResponseTimeToThemTrend,
  firstReplyResponseTimeToThem,
  firstReplyResponseTimeToThemTrend,
  viewerReplyResponseTimeToThem,
  viewerReplyResponseTimeToThemTrend,
}: {
  fields: any[];
  onlyIdentifier: boolean;
  contactUuid: string;
  contactName: string;
  contactEmail: string;
  companyName?: string;
  isLeaver?: boolean;
  isAssistant?: boolean;
  status: string;
  rank: string;
  roles?: string[];
  phoneNumbers?: any[];
  segment: string;
  relationshipScore: number;
  relationshipScoreTrend: string;
  colleaguesInteracting: number;
  interest: number;
  interestTrend: "increase" | "decrease" | "maintain";
  effort: number;
  effortTrend: "increase" | "decrease" | "maintain";
  lastInteraction: { date: string; contact: string; colleague: string };
  lastMeeting: { date: string; contact: string; colleague: string };
  emailInbound: number;
  emailOutbound: number;
  personal: boolean;
  viewerStatus: string;
  viewerInterest: number;
  viewerEffort: number;
  viewerRelationshipScore: number;
  viewerRelationshipScoreTrend: string;
  viewerLastInteraction: { date: string; contact: string; colleague: string };
  viewerLastMeeting: { date: string; contact: string; colleague: string };
  viewerEmailInbound: number;
  viewerEmailOutbound: number;
  viewerReplyResponseTimeFromThem: number;
  viewerReplyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  replyResponseTimeFromThem: number;
  replyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  firstReplyResponseTimeFromThem: number;
  firstReplyResponseTimeFromThemTrend: "increase" | "decrease" | "maintain";
  viewerFirstReplyResponseTimeFromThem: number;
  viewerFirstReplyResponseTimeFromThemTrend:
    | "increase"
    | "decrease"
    | "maintain";
  replyResponseTimeToThem: number;
  replyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  viewerFirstReplyResponseTimeToThem: number;
  viewerFirstReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  firstReplyResponseTimeToThem: number;
  firstReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
  viewerReplyResponseTimeToThem: number;
  viewerReplyResponseTimeToThemTrend: "increase" | "decrease" | "maintain";
}) => {
  const cells: any[] = [];
  const getStatusLabel = (value: string) =>
    +value !== ActivityType.Undefined && value !== ""
      ? ActivityType[+value]
      : "";

  const phoneNumber = firstPhoneNumber(phoneNumbers);
  const aRole = firstRole(roles);

  cells.push(
    <IdentifierCell
      type="contact"
      id={contactUuid}
      meta={{
        email: contactEmail,
        phoneNumbers: phoneNumbers || [],
        onlyIdentifier,
      }}
      flags={{ isAssistant, isLeaver }}
      title={contactName}
      description={companyName || aRole}
    />,
  );

  let metaFromField: any = {
    email: { text: contactEmail },
    role: { text: aRole },
    phoneNumber: { text: phoneNumber },
    interest: {
      trend: interestTrend,
      value: interest,
      secondaryValue: viewerInterest,
      secondaryLabel: personal ? "All" : "You",
    },
    effort: {
      trend: effortTrend,
      value: effort,
      secondaryValue: viewerEffort,
      secondaryLabel: personal ? "All" : "You",
    },
    rank: {
      color: RankType[+rank].toLocaleLowerCase(),
      label: +rank === RankType.Unranked ? "" : RankType[+rank],
    },
    segment: { text: SegmentType[+segment] },
    colleaguesInteracting: { text: colleaguesInteracting },
  };

  if (personal) {
    metaFromField = mergeRight(metaFromField, {
      relationshipScore: {
        viewer: {
          value: relationshipScore,
          description: TrendType[+relationshipScoreTrend],
          color: propOr("green", relationshipScoreTrend, trendColors),
        },
      },
      viewerLastInteraction: {
        when:
          lastInteraction.date &&
          formatDifferenceFromNow(new Date(lastInteraction.date)),
        tagColor: propOr("green", status, activityColors),
        tagLabel: getStatusLabel(status),
      },
      colleagueLastInteraction: {
        when:
          viewerLastInteraction.date &&
          formatDifferenceFromNow(new Date(viewerLastInteraction.date)),
        who: viewerLastInteraction.colleague,
        tagColor: propOr(undefined, viewerStatus, activityColors),
        tagLabel: getStatusLabel(viewerStatus),
      },
      viewerEmailBreakdown: {
        emailsIn: emailInbound,
        emailsOut: emailOutbound,
      },
      allEmailBreakdown: {
        emailsIn: viewerEmailInbound,
        emailsOut: viewerEmailOutbound,
      },
      viewerLastMeeting: {
        when:
          lastMeeting.date &&
          formatDifferenceFromNow(new Date(lastMeeting.date)),
      },
      colleagueLastMeeting: {
        when:
          viewerLastMeeting.date &&
          formatDifferenceFromNow(new Date(viewerLastMeeting.date)),
        who: viewerLastMeeting.colleague,
      },
      yourAverageResponseTime: {
        trends: [
          {
            value: viewerReplyResponseTimeToThem,
            trend: viewerReplyResponseTimeToThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: viewerFirstReplyResponseTimeToThem,
            trend: viewerFirstReplyResponseTimeToThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      allColleaguesAverageResponseTime: {
        trends: [
          {
            value: replyResponseTimeToThem,
            trend: replyResponseTimeToThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: firstReplyResponseTimeToThem,
            trend: firstReplyResponseTimeToThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      theirAverageResponseTimeToYou: {
        trends: [
          {
            value: viewerReplyResponseTimeFromThem,
            trend: viewerReplyResponseTimeFromThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: viewerFirstReplyResponseTimeFromThem,
            trend: viewerFirstReplyResponseTimeFromThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      theirAverageResponseTimeToAllColleagues: {
        trends: [
          {
            value: replyResponseTimeFromThem,
            trend: replyResponseTimeFromThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: firstReplyResponseTimeFromThem,
            trend: firstReplyResponseTimeFromThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
    });
  } else {
    metaFromField = mergeRight(metaFromField, {
      relationshipScore: {
        global: {
          value: relationshipScore,
          description: TrendType[+relationshipScoreTrend],
          color: propOr("green", relationshipScoreTrend, trendColors),
        },
        viewer: {
          value: viewerRelationshipScore,
          description: TrendType[+viewerRelationshipScoreTrend],
          color: propOr("green", viewerRelationshipScoreTrend, trendColors),
        },
      },
      viewerLastInteraction: {
        when:
          viewerLastInteraction.date &&
          formatDifferenceFromNow(new Date(viewerLastInteraction.date)),
        tagColor: propOr(undefined, viewerStatus, activityColors),
        tagLabel: getStatusLabel(viewerStatus),
      },
      colleagueLastInteraction: {
        when:
          lastInteraction.date &&
          formatDifferenceFromNow(new Date(lastInteraction.date)),
        who: lastInteraction.colleague,
        tagColor: propOr("green", status, activityColors),
        tagLabel: getStatusLabel(status),
      },
      viewerEmailBreakdown: {
        emailsIn: viewerEmailInbound,
        emailsOut: viewerEmailOutbound,
      },
      allEmailBreakdown: {
        emailsIn: emailInbound,
        emailsOut: emailOutbound,
      },
      viewerLastMeeting: {
        when:
          viewerLastMeeting.date &&
          formatDifferenceFromNow(new Date(viewerLastMeeting.date)),
        who: viewerLastMeeting.colleague,
      },
      colleagueLastMeeting: {
        when:
          lastMeeting.date &&
          formatDifferenceFromNow(new Date(lastMeeting.date)),
        who: lastMeeting.colleague,
      },
      yourAverageResponseTime: {
        trends: [
          {
            value: viewerReplyResponseTimeToThem,
            trend: viewerReplyResponseTimeToThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: viewerFirstReplyResponseTimeToThem,
            trend: viewerFirstReplyResponseTimeToThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      allColleaguesAverageResponseTime: {
        trends: [
          {
            value: replyResponseTimeToThem,
            trend: replyResponseTimeToThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: firstReplyResponseTimeToThem,
            trend: firstReplyResponseTimeToThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      theirAverageResponseTimeToYou: {
        trends: [
          {
            value: viewerReplyResponseTimeFromThem,
            trend: viewerReplyResponseTimeFromThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: viewerFirstReplyResponseTimeFromThem,
            trend: viewerFirstReplyResponseTimeFromThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
      theirAverageResponseTimeToAllColleagues: {
        trends: [
          {
            value: replyResponseTimeFromThem,
            trend: replyResponseTimeFromThemTrend,
            //subtitle: "All emails",
            reversed: true,
          },
          /*{
            value: firstReplyResponseTimeFromThem,
            trend: firstReplyResponseTimeFromThemTrend,
            subtitle: "First response",
            reversed: true,
          },*/
        ],
      },
    });
  }

  fields.forEach((option) => {
    cells.push(
      <GenericCell
        type={option.type}
        meta={prop(option.field, metaFromField)}
      />,
    );
  });

  return <Row cells={cells} />;
};

const ContactTable = ({
  dataSource = null,
  fields,
  isLoading,
  personal,
  nameSource,
  showFlags,
  sorting,
  setSorting,
}: {
  dataSource: Array<object> | null;
  fields: ITableField[];
  isLoading: boolean;
  personal: boolean;
  nameSource: string;
  showFlags?: boolean;
  sorting: string;
  setSorting: (value: any) => void;
}) => {
  const sortingState = (field?: string): SortType | undefined => {
    if (field && sorting.endsWith(field)) {
      return sorting.startsWith("-") ? "desc" : "asc";
    }
  };
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const tableRef = useRef(null);
  const [minifyFirstColumn, setMinifyFirstColumn] = useState(false);
  const handleScroll = () => {
    const table = tableRef.current as HTMLTableElement | null;
    if (table !== null) {
      const scrollLeft = table.scrollLeft;
      const maxScroll = table.scrollWidth - table.clientWidth;

      if (
        !isDesktop &&
        scrollLeft > 100 &&
        !minifyFirstColumn &&
        maxScroll > 300
      ) {
        setMinifyFirstColumn(true);
      } else if (scrollLeft <= 100 && minifyFirstColumn) {
        setMinifyFirstColumn(false);
      }
    }
  };

  return (
    <div
      className="overflow-x-auto whitespace-nowrap"
      onScroll={handleScroll}
      ref={tableRef}
    >
      <table className="w-full border-separate">
        <thead>
          <tr>
            <Header
              text={minifyFirstColumn ? "" : "Contact name"}
              sortable
              sticky
              sortState={sortingState(nameSource)}
              onSort={() => setSorting(flipSorting(nameSource, sorting))}
            />
            {fields.map((option) => (
              <Header
                text={option.label}
                sortable={!!option.source}
                sortState={sortingState(option.source)}
                key={"header-" + option.field}
                onSort={() =>
                  setSorting(flipSorting(option.source || "", sorting))
                }
              />
            ))}
          </tr>
        </thead>
        <tbody className={classNames({ "animate-pulse": isLoading })}>
          {isLoading ? (
            <>
              {Array(10)
                .fill(null)
                .map((_, i) => (
                  <TableSkeletonRow fields={fields} key={i} />
                ))}
            </>
          ) : (
            <>
              {dataSource?.map((row: any) => (
                <ContactRow
                  fields={fields}
                  personal={personal}
                  onlyIdentifier={minifyFirstColumn}
                  contactUuid={row["dim_contact.uuid"]}
                  contactName={row["dim_contact.full_name"]}
                  contactEmail={row["dim_contact.email"]}
                  companyName={row["dim_company.display_name"]}
                  isLeaver={showFlags && row["dim_contact.is_leaver"]}
                  isAssistant={showFlags && row["dim_contact.is_assistant"]}
                  roles={JSON.parse(propOr("[]", "dim_contact.roles", row))}
                  phoneNumbers={JSON.parse(
                    propOr("[]", "dim_contact.phone_numbers", row),
                  )}
                  key={row["dim_contact.uuid"]}
                  emailInbound={row["fact_table.inbound_current"]}
                  emailOutbound={row["fact_table.outbound_current"]}
                  {...extractSharedMetrics(row, personal)}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ContactTable;
