import Button from "components/Button";
import Modal from "components/Modal";

const CompanyAssociatedAliasesModal = ({
  visible,
  domains,
  companyName,
  onClose,
}: {
  visible: boolean;
  domains?: string[];
  companyName?: string;
  onClose: () => void;
}) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="flex h-full flex-col">
        <div className="flex items-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            <i className="icon-info-circle" />
            Associated domains
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="my-4 flex flex-col overflow-y-auto text-base">
          <div className="text-lg text-navy">List of associated domains</div>
          <div className="text-metal max-w-[90%]">
            The following email domains have been automatically identified as
            belonging to {companyName}.
          </div>
          <div
            className="mt-4 flex flex-col gap-2 border rounded-lg border-dust-dark p-4 h-52 overflow-y-auto"
            data-testid="company-associated-domains-list"
          >
            {domains?.map((domain, index) => (
              <div key={index} className="text-lg text-navy">
                @{domain}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompanyAssociatedAliasesModal;
